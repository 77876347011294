import api from "@/lib/calendesk-js-library/api/api";
import { trans } from "@/lib/calendesk-js-library/prototypes/trans";
import { config } from "@/lib/calendesk-js-library/prototypes/config";
import { adminConfig } from "@/lib/calendesk-js-library/prototypes/adminConfig";
import moment from "@/lib/calendesk-js-library/plugins/moment";
import helpers, {
  cloneObject,
  userTimeZone,
  getEmployeeForId,
  getEmployeeName,
  getServiceAndItsTypeForId,
  getServiceName,
  getUserName,
  getConvertedEventDates,
  platformTimeZone,
} from "@/lib/calendesk-js-library/tools/helpers";
import employeeColors from "@/calendesk/tools/employeeColors";
import { getItem } from "@/lib/calendesk-js-library/tools/localStorageManager";
import { EventData } from "@/views/dashboard/pages/Calendar/components/EventData";
import { GroupEventData } from "@/views/dashboard/pages/Calendar/components/GroupEventData";
import store from "@/store";

export const calendarWeekdays = {
  monday: 1,
  tuesday: 2,
  wednesday: 3,
  thursday: 4,
  friday: 5,
  saturday: 6,
  sunday: 0,
};

const calendarHelpers = {
  businessHours() {
    return config("company_working_hours")
      .map((day) => {
        if (day.is_open) {
          return {
            daysOfWeek: [calendarWeekdays[day.day]],
            startTime: day.open,
            endTime: day.close,
          };
        }
      })
      .filter((el) => el != null);
  },

  getCalendarEvents(events) {
    const result = [];
    events.forEach((element) => {
      const type = element.event_type;
      const data = element.event_data;
      const isBlockingSpot = type === helpers.eventTypes.unavailableBookingSlot;
      const isBooking = type === helpers.eventTypes.booking;
      const isGroupBooking = type === helpers.eventTypes.groupBooking;

      const convertedEventDates = getConvertedEventDates(
        element.starts_at,
        element.ends_at,
        !(element.start_time && element.end_time),
        true
      );

      if (data.employee_id) {
        data.employee = getEmployeeForId(data.employee_id);
      }

      if ((isBooking || isGroupBooking) && data.service_id) {
        data.service = getServiceAndItsTypeForId(
          data.service_id,
          data.service_type_id
        );
      }

      const item = {
        id: element.id,
        multi_slot_group_id: isGroupBooking ? element.id : null,
        type: element.event_type,
        title: getCalendarEventTileDescription(data, type),
        tooltip_title: getCalendarEventBasicDescription(data, type),
        allDay: convertedEventDates.allDay,

        start: convertedEventDates.allDay
          ? convertedEventDates.startDate.format(helpers.dateFormat)
          : convertedEventDates.startDate.format(helpers.dateTimeFormat),
        end: convertedEventDates.allDay
          ? convertedEventDates.endDate.format(helpers.dateFormat)
          : convertedEventDates.endDate.format(helpers.dateTimeFormat),
        start_date: element.start_date,
        end_date: element.end_date,
        start_time: element.start_time,
        end_time: element.end_time,
        starts_at: element.starts_at,
        ends_at: element.ends_at,

        employee_id: data.employee_id,
        user_id: isBooking ? data.user_id : null,
        service_id: isBooking || isGroupBooking ? data.service_id : null,
        service_type_id:
          isBooking || isGroupBooking ? data.service_type_id : null,
        status: isBooking ? data.status : null,
        paid: isBooking ? data.paid : null,
        group_id: data.group_id,
        description: data.description,
        tags: isBooking ? data.tags : null,
        products: isBooking ? data.simple_store_products : null,
        classNames: isBlockingSpot ? ["unavailable_spot"] : [],
        bookings: isGroupBooking ? data.bookings : null,
      };

      if (data.employee) {
        item.resourceId = data.employee_id;

        if (isBooking && data.status === "cancelled") {
          item.backgroundColor =
            employeeColors.defaultCanceledBookingBackgroundColor;
          item.textColor = employeeColors.defaultCanceledBookingTextColor;
          item.color = employeeColors.defaultCanceledBookingBackgroundColor;
        } else if (isBooking || isGroupBooking) {
          item.backgroundColor = data.employee.color;
          item.textColor = employeeColors.getTextColorForBackgroundColor(
            item.backgroundColor
          );
          item.color = data.employee.color;
        } else if (isBlockingSpot) {
          item.textColor =
            employeeColors.defaultUnavailableBookingSpotTextColor;
          item.color =
            employeeColors.defaultUnavailableBookingSpotBackgroundColor;
        }
      } else {
        if (isBlockingSpot) {
          item.textColor =
            employeeColors.defaultUnavailableBookingSpotTextColor;
          item.color =
            employeeColors.defaultUnavailableBookingSpotBackgroundColor;

          item.resourceIds =
            store.getters["calendar/getSelectedEmployees"] || [];
        } else {
          item.textColor = employeeColors.defaultEventTextColor;
          item.color = employeeColors.defaultEventBackgroundColor;
        }
      }

      result.push(item);
    });

    return result;
  },
};

export function getCalendarEventTileDescription(data, type) {
  let name = getCalendarEventBasicDescription(data, type);

  if (type === helpers.eventTypes.unavailableBookingSlot && data.description) {
    name += ` (${data.description})`;
  }

  return name;
}

export function getCalendarEventBasicDescription(data, type) {
  let name = "";
  let employeeName = "";
  let employeeWithName = "";
  let serviceName = "";
  let userName = "";
  let serviceNameWithSpaces = "";

  if (data.user) {
    userName = getUserName(data.user, null, true);
  }

  if (data.employee) {
    employeeName = getEmployeeName(data.employee, true);
    employeeWithName = " " + trans("with") + " " + employeeName;
  }

  if (data.service) {
    serviceName = getServiceName(data.service, null, true, false, true);
    serviceNameWithSpaces = " - " + serviceName;
  }

  if (type === helpers.eventTypes.booking) {
    name = `${userName}${employeeWithName}${serviceNameWithSpaces}`;
  } else if (type === helpers.eventTypes.groupBooking) {
    name = `${serviceName}${employeeWithName} (${
      data.bookings ? trans("bookings") + ": " + data.bookings.length + "" : ""
    })`;
  } else if (type === helpers.eventTypes.unavailableBookingSlot) {
    if (employeeName) {
      name = `${trans("unavailable_spot")}: ${employeeName}`;
    } else {
      name = `${trans("unavailable_spot")}: ${trans("all_employees")}`;
    }
  }

  return name;
}

export function getEvents(info, successCallback, failureCallback) {
  const data = {
    event_date_from: moment(info.start)
      .tz(platformTimeZone())
      .format(helpers.dateFormat),
    event_date_to: moment(info.end)
      .tz(platformTimeZone())
      .format(helpers.dateFormat),
    with: "prods,usr,tags,up",
    force_ubs: 1,
  };

  const selectedEmployees = getItem("calendarSelectedEmployees", true, true);
  if (selectedEmployees && selectedEmployees.length > 0) {
    data.employee_ids = selectedEmployees.join();
  }

  const canceledBookings = adminConfig("app_calendar_show_canceled");
  if (canceledBookings) {
    data.status_ids = "approved,done,payment,waiting,cancelled";
  } else {
    data.status_ids = "approved,done,payment,waiting";
  }

  const doNotGroupBookings = adminConfig("app_calendar_do_not_group_bookings");
  if (doNotGroupBookings) {
    data.event_types = `${helpers.eventTypes.booking},${helpers.eventTypes.unavailableBookingSlot}`;
  }

  api
    .events(data)
    .then((response) => {
      successCallback(calendarHelpers.getCalendarEvents(response.data));
    })
    .catch((error) => {
      failureCallback(error);
    });
}

export function getResourceFreeSlots(info, successCallback) {
  const employeeWorkingHours = adminConfig(
    "app_calendar_employee_working_hours_visible"
  );
  const events = [];
  if (employeeWorkingHours) {
    const schedules = store.getters["availabilitySchedule/schedules"];
    if (schedules) {
      const selectedEmployees = getItem(
        "calendarSelectedEmployees",
        true,
        true
      );
      if (selectedEmployees && selectedEmployees.length > 0) {
        schedules.forEach((schedule) => {
          if (schedule.employees) {
            schedule.employees.forEach((employee) => {
              if (selectedEmployees.includes(employee.id)) {
                schedule.rules.forEach((rule) => {
                  rule.intervals.forEach((interval) => {
                    if (rule.type === helpers.scheduleRuleType.DATE) {
                      const startDate = moment
                        .tz(
                          rule.date + "T" + interval.start_time,
                          helpers.isoFormat,
                          schedule.timezone
                        )
                        .tz(userTimeZone());

                      const endDate = moment
                        .tz(
                          rule.date + "T" + interval.end_time,
                          helpers.isoFormat,
                          schedule.timezone
                        )
                        .tz(userTimeZone());

                      events.push({
                        start: startDate.format(helpers.isoFormat),
                        end: endDate.format(helpers.isoFormat),
                        display: "background",
                        resourceId: employee.id,
                        schedule: schedule,
                        scheduleRuleType: helpers.scheduleRuleType.DATE,
                        employeeId: employee.id,
                        start_time: startDate.format(helpers.timeFormat),
                        end_time: endDate.format(helpers.timeFormat),
                      });
                    } else if (rule.type === helpers.scheduleRuleType.WDAY) {
                      const startDate = moment
                        .tz(
                          interval.start_time,
                          helpers.timeFormat,
                          schedule.timezone
                        )
                        .tz(userTimeZone());

                      const endDate = moment
                        .tz(
                          interval.end_time,
                          helpers.timeFormat,
                          schedule.timezone
                        )
                        .tz(userTimeZone());

                      const startTime = startDate.format(helpers.timeFormat);
                      const endTime = endDate.format(helpers.timeFormat);

                      events.push({
                        daysOfWeek: [helpers.weekdaysSorter2[rule.wday]],
                        startTime: startTime,
                        endTime: endTime,
                        start_time: startTime,
                        end_time: endTime,
                        display: "background",
                        resourceId: employee.id,
                        schedule: schedule,
                        scheduleRuleType: helpers.scheduleRuleType.WDAY,
                        employeeId: employee.id,
                      });
                    }
                  });
                });
              }
            });
          }
        });
      }
    }
  }

  successCallback(events);
}

export function prepareBookingTimeObject(data, booking) {
  const userTz = userTimeZone();
  const platformTz = platformTimeZone();

  data.start_date = booking.startDate;
  data.end_date = booking.endDate;

  if (booking.allDay) {
    const day = moment.duration(1, "d");
    data.end_date = moment(booking.endDate).add(day).format(helpers.dateFormat);
    data.start_time = null;
    data.end_time = null;
  } else {
    data.start_time = booking.startTime;
    data.end_time = booking.endTime;
  }

  if (userTz !== platformTz) {
    // Convert start_date and end_date from userTz to platformTz
    const startDateTimeUserTz = data.start_time
      ? moment.tz(
          `${data.start_date} ${data.start_time}`,
          helpers.dateTimeFormat,
          userTz
        )
      : moment.tz(data.start_date, helpers.dateFormat, userTz);

    const endDateTimeUserTz = data.end_time
      ? moment.tz(
          `${data.end_date} ${data.end_time}`,
          helpers.dateTimeFormat,
          userTz
        )
      : moment.tz(data.end_date, helpers.dateFormat, userTz);

    const startDateTimePlatformTz = startDateTimeUserTz.clone().tz(platformTz);
    const endDateTimePlatformTz = endDateTimeUserTz.clone().tz(platformTz);

    data.start_date = startDateTimePlatformTz.format(helpers.dateFormat);
    data.end_date = endDateTimePlatformTz.format(helpers.dateFormat);

    if (!booking.allDay) {
      data.start_time = startDateTimePlatformTz.format(helpers.timeFormat);
      data.end_time = endDateTimePlatformTz.format(helpers.timeFormat);
    }
  }
}

export function prepareUpdateGroupBookingObject(booking) {
  const data = {
    ...(booking.employee && { employee_id: booking.employee.id }),
    ...(booking.users && { user_ids: booking.users.map((user) => user.id) }),
    ...(booking.startDate && { start_date: booking.startDate }),
    ...(booking.endDate && { end_date: booking.endDate }),
  };

  if (!booking.updateWithoutAllData) {
    if (booking.skipNotifications) {
      data.skip_notifications = booking.skipNotifications;
    }

    if (booking.serviceLocationId) {
      data.service_location_id = booking.serviceLocationId;
    }

    if (booking.status) {
      data.status = booking.status;
    }

    if (booking.service) {
      data.service_id = booking.service.id;

      if (booking.service.selectedType) {
        data.service_type_id = booking.service.selectedType.id;
      }
    }

    if (booking.groupId) {
      data.group_id = booking.groupId;
    }

    if (booking.startFromId) {
      data.start_from_id = booking.startFromId;
    }

    if (booking.recurrenceParams) {
      data.recurrence_params = booking.recurrenceParams;
    }
  }

  prepareBookingTimeObject(data, booking);

  return data;
}

export function prepareBookingObject(booking) {
  const data = {
    ...(booking.employee && { employee_id: booking.employee.id }),
    ...(booking.user && { user_id: booking.user.id }),
    ...(booking.users && { user_ids: booking.users.map((user) => user.id) }),
    ...(booking.startDate && { start_date: booking.startDate }),
    ...(booking.endDate && { end_date: booking.endDate }),
  };

  if (!booking.updateWithoutAllData) {
    data.tags = booking.tags && booking.tags.length > 0 ? booking.tags : [];
    data.simple_store_product_ids =
      booking.products && booking.products.length > 0 ? booking.products : [];
    data.description = booking.description ? booking.description : null;

    if (booking.skipNotifications) {
      data.skip_notifications = booking.skipNotifications;
    }

    if (booking.serviceLocationId) {
      data.service_location_id = booking.serviceLocationId;
    }

    if (booking.recurrenceParams) {
      data.recurrence_params = booking.recurrenceParams;
    }

    if (booking.status) {
      data.status = booking.status;
    }

    if (booking.service) {
      data.service_id = booking.service.id;

      if (booking.service.selectedType) {
        data.service_type_id = booking.service.selectedType.id;
      }
    }

    if (booking.groupId) {
      data.group_id = booking.groupId;
    }

    if (booking.startFromId) {
      data.start_from_id = booking.startFromId;
    }
  }

  prepareBookingTimeObject(data, booking);

  return data;
}

export function prepareUnavailableBookingSpotObject(slot) {
  const data = {
    ...(slot.employee && { employee_id: slot.employee.id }),
    start_date: slot.startDate,
    end_date: slot.endDate,
    description: slot.description,
    ...(slot.recurrenceParams && { recurrence_params: slot.recurrenceParams }),
  };

  if (slot.groupId) {
    data.group_id = slot.groupId;
  }

  if (slot.startFromId) {
    data.start_from_id = slot.startFromId;
  }

  prepareBookingTimeObject(data, slot);

  return data;
}

export function displayCalendarDate(item, breakLines = false) {
  const lineBreak = breakLines ? "<br>" : "";
  const userTz = userTimeZone();
  const allDay = !(item.start_time && item.end_time);

  const convertedEventDates = getConvertedEventDates(
    item.starts_at,
    item.ends_at,
    allDay
  );

  const timezoneString = adminConfig("app_employees_time_zone_enabled")
    ? `${lineBreak}(${userTz})`
    : "";

  const endDate = convertedEventDates.endDate;
  const startDate = convertedEventDates.startDate;

  if (allDay) {
    // If the start date and end date are the same, return the start date
    if (startDate.isSame(endDate, "day")) {
      return `${startDate.format(
        helpers.dayMonthAndYearDate
      )} ${timezoneString}`;
    }

    // If the event spans multiple days, return the start and end dates
    return `${startDate.format(
      helpers.dayMonthAndYearDate
    )} - ${lineBreak}${endDate.format(
      helpers.dayMonthAndYearDate
    )} ${timezoneString}`;
  }

  // Events with end hours
  // If the event starts and ends on the same day
  if (startDate.isSame(endDate, "day")) {
    return `${startDate.format(
      helpers.dayMonthAndYearDate
    )}, ${lineBreak}${startDate.format(helpers.timeFormat)} - ${endDate.format(
      helpers.timeFormat
    )} ${timezoneString}`;
  }

  // If the event spans multiple days
  return `${startDate.format(helpers.dayMonthAndYearDate)} ${startDate.format(
    helpers.timeFormat
  )} - ${lineBreak}${endDate.format(
    helpers.dayMonthAndYearDate
  )} ${endDate.format(helpers.timeFormat)} ${timezoneString}`;
}

export function createEventDataFromUnavailableBookingSlotData(
  unavailableBookingSlotData
) {
  const event = new EventData();
  event.data = cloneObject(unavailableBookingSlotData);

  const allDay =
    !unavailableBookingSlotData.start_time &&
    !unavailableBookingSlotData.end_time;
  event.type = helpers.eventTypes.unavailableBookingSlot;
  event.allDay = allDay;

  const convertedEventDates = getConvertedEventDates(
    unavailableBookingSlotData.starts_at,
    unavailableBookingSlotData.ends_at,
    allDay
  );

  event.startDate = convertedEventDates.formattedStartDate;
  event.startTime = convertedEventDates.formattedStartTime;
  event.endDate = convertedEventDates.formattedEndDate;
  event.endTime = convertedEventDates.formattedEndTime;

  event.startsAt = unavailableBookingSlotData.starts_at;
  event.endsAt = unavailableBookingSlotData.ends_at;

  event.id = unavailableBookingSlotData.id;
  event.description = unavailableBookingSlotData.description;
  event.groupId = unavailableBookingSlotData.group_id;
  event.recurrenceParams = unavailableBookingSlotData.recurrence_params;
  event.employee = unavailableBookingSlotData.employee || null;

  event.createdAt = unavailableBookingSlotData.created_at;
  event.createdBy = unavailableBookingSlotData.created_by;
  event.updatedAt = unavailableBookingSlotData.updated_at;
  event.updatedBy = unavailableBookingSlotData.updated_by;

  event.calendarFullDate = displayCalendarDate(unavailableBookingSlotData);

  return event;
}

export function createEventDataFromUnavailableBookingSlotDataRawEvent(
  rawEvent
) {
  const result = createEventDataFromUnavailableBookingSlotData(
    rawEvent.event_data
  );
  result.data = cloneObject(rawEvent);

  return result;
}

export function createEventDataFromBookingData(bookingData) {
  const event = new EventData();
  event.data = cloneObject(bookingData);

  const allDay = !bookingData.start_time && !bookingData.end_time;

  event.type = helpers.eventTypes.booking;
  event.allDay = allDay;

  const convertedEventDates = getConvertedEventDates(
    bookingData.starts_at,
    bookingData.ends_at,
    allDay
  );

  event.startDate = convertedEventDates.formattedStartDate;
  event.startTime = convertedEventDates.formattedStartTime;
  event.endDate = convertedEventDates.formattedEndDate;
  event.endTime = convertedEventDates.formattedEndTime;

  event.startsAt = bookingData.starts_at;
  event.endsAt = bookingData.ends_at;

  event.id = bookingData.id;
  event.description = bookingData.description;
  event.tags = bookingData.tags;
  event.products = bookingData.simple_store_products;
  event.status = bookingData.status;
  event.groupId = bookingData.group_id;
  event.recurrenceParams = bookingData.recurrence_params;
  event.paid = bookingData.paid;
  event.location = bookingData.location;
  event.googleMeetUrl = bookingData.google_meet_url;
  event.zoomJoinUrl = bookingData.zoom_join_url;
  event.zoomStartUrl = bookingData.zoom_start_url;
  event.teamsUrl = bookingData.teams_url;
  event.skypeUrl = bookingData.skype_url;
  event.customerWhatsAppUrl = bookingData.customer_whatsapp_url;
  event.employeeWhatsAppUrl = bookingData.employee_whatsapp_url;
  event.createdAt = bookingData.created_at;
  event.createdBy = bookingData.created_by;
  event.updatedAt = bookingData.updated_at;
  event.updatedBy = bookingData.updated_by;
  event.draftUuid = bookingData.draft_uuid;
  event.control = bookingData.control;

  if (bookingData.employee) {
    event.employee = bookingData.employee;
  } else if (bookingData.employee_id) {
    event.employee = getEmployeeForId(bookingData.employee_id);
  }

  event.user = bookingData.user || null;
  event.multiSlotGroupId = bookingData.multi_slot_group_id;
  event.paymentTransaction = bookingData.payment_transaction;
  event.simpleStoreProductPaymentTransactions =
    bookingData.simple_store_product_payment_transactions;
  event.invoiceNumber = bookingData.invoice_number;

  event.calendarFullDate = displayCalendarDate(bookingData);
  event.customFields = bookingData.custom_fields;

  if (bookingData.service) {
    event.service = bookingData.service;
    event.service.selectedType = bookingData.service_type || null;
  } else if (bookingData.service_id) {
    event.service = getServiceAndItsTypeForId(
      bookingData.service_id,
      bookingData.service_type_id
    );
  }

  return event;
}

export function createEventDataFromBookingRawEvent(rawEvent) {
  const result = createEventDataFromBookingData(rawEvent.event_data);
  result.data = cloneObject(rawEvent);

  return result;
}

export function createEventDataFromGroupBookingRawEvent(rawEvent) {
  const event = new GroupEventData();
  event.data = cloneObject(rawEvent);

  const eventData = rawEvent.event_data;

  const allDay = !rawEvent.start_time && !rawEvent.end_time;

  event.type = helpers.eventTypes.groupBooking;
  event.allDay = allDay;

  const convertedEventDates = getConvertedEventDates(
    rawEvent.starts_at,
    rawEvent.ends_at,
    allDay
  );

  event.startDate = convertedEventDates.formattedStartDate;
  event.startTime = convertedEventDates.formattedStartTime;
  event.endDate = convertedEventDates.formattedEndDate;
  event.endTime = convertedEventDates.formattedEndTime;

  event.startsAt = rawEvent.starts_at;
  event.endsAt = rawEvent.ends_at;

  event.groupId = null;
  event.recurrenceParams = null;
  event.location = null;
  event.googleMeetUrl = eventData.google_meet_url;
  event.zoomJoinUrl = eventData.zoom_join_url;
  event.zoomStartUrl = null;
  event.teamsUrl = eventData.teams_url;
  event.skypeUrl = eventData.skype_url;
  event.customerWhatsAppUrl = null;
  event.employeeWhatsAppUrl = null;

  if (eventData.employee) {
    event.employee = eventData.employee;
  } else if (eventData.employee_id) {
    event.employee = getEmployeeForId(eventData.employee_id);
  }

  const bookings = eventData.bookings ?? [];

  event.bookings = bookings.map((booking) => {
    booking.starts_at = rawEvent.starts_at;
    booking.ends_at = rawEvent.ends_at;
    return createEventDataFromBookingData(booking);
  });

  event.users = bookings
    .filter((booking) => !!booking.user)
    .map((booking) => {
      const user = booking.user;
      user.booking_status = booking.status;

      return user;
    });

  event.multiSlotGroupId = rawEvent.id;
  event.calendarFullDate = displayCalendarDate(rawEvent);

  if (eventData.service) {
    event.service = eventData.service;
    event.service.selectedType = eventData.service_type || null;
  } else if (eventData.service_id) {
    event.service = getServiceAndItsTypeForId(
      eventData.service_id,
      eventData.service_type_id
    );
  }

  return event;
}

export function createEventDataFromGroupBookingData(groupBookingData) {
  const event = new GroupEventData();
  event.data = cloneObject(groupBookingData);

  const allDay = !groupBookingData.start_time && !groupBookingData.end_time;

  const convertedEventDates = getConvertedEventDates(
    groupBookingData.starts_at,
    groupBookingData.ends_at,
    allDay
  );

  event.type = helpers.eventTypes.groupBooking;
  event.allDay = allDay;

  event.startDate = convertedEventDates.formattedStartDate;
  event.startTime = convertedEventDates.formattedStartTime;
  event.endDate = convertedEventDates.formattedEndDate;
  event.endTime = convertedEventDates.formattedEndTime;
  event.startsAt = groupBookingData.starts_at;
  event.endsAt = groupBookingData.ends_at;

  event.groupId = null;
  event.recurrenceParams = groupBookingData.recurrence_params;
  event.location = groupBookingData.location;
  event.googleMeetUrl = groupBookingData.google_meet_url;
  event.zoomJoinUrl = groupBookingData.zoom_join_url;
  event.zoomStartUrl = groupBookingData.zoom_start_url;
  event.teamsUrl = groupBookingData.teams_url;
  event.skypeUrl = groupBookingData.skype_url;
  event.customerWhatsAppUrl = null;
  event.employeeWhatsAppUrl = groupBookingData.employee_whatsapp_url;
  event.employee = groupBookingData.employee || null;

  const bookings = groupBookingData.bookings ?? [];

  event.bookings = bookings.map((booking) => {
    booking.starts_at = groupBookingData.starts_at;
    booking.ends_at = groupBookingData.ends_at;
    return createEventDataFromBookingData(booking);
  });

  event.users = bookings
    .filter((booking) => !!booking.user)
    .map((booking) => {
      const user = booking.user;
      user.booking_status = booking.status;

      return user;
    });

  event.multiSlotGroupId = groupBookingData.multi_slot_group_id;
  event.calendarFullDate = displayCalendarDate(groupBookingData);

  if (groupBookingData.service) {
    event.service = groupBookingData.service;
    event.service.selectedType = groupBookingData.service_type || null;
  }

  return event;
}

export default calendarHelpers;
